
import { AxiosPromise } from "axios"
import request from "../request"
import { scoreApi } from "./type"
export default {

    scoreInfo(data: {
        kskm: string,
        score: number,
        type: string,


    }): Promise<scoreApi.scoreInfoRes> {
        return request({
            url: '/score/info',
            method: 'post',
            data: data,

        })

    },
    scoreInfoList(params: {
        pageNum: number,
        pageSize: number
    }) : Promise<scoreApi.scoreInfoListRes>{
        return request({
            url: '/score/info/list',
            method: 'get',
            params: params,

        })
    }



}


