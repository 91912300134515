import request from "../request"
import { trafficApi } from "./type"
export default {
    trafficClassList():Promise<trafficApi.classList>{
        return request({
            url:"/traffic/class/list",
            method:'get'
        })

    },
    trafficSignList(params:{
        parentId:number,

    }):Promise<trafficApi.signList>{
        return request({
            url:"/traffic/sign/list",
            method:'get',
            params
        })
    }


}